<script lang="ts" setup>
import type { ComponentSize } from '~/types/components/componentSize';

withDefaults(
    defineProps<{
        badges: string[];
        isHorizontal?: boolean;
        noMargin?: boolean;
        size?: ComponentSize;
    }>(),
    {
        noMargin: false,
        size: 'lg',
    },
);

interface Map {
    [key: string]: string;
}

const colorMap: Map = {
    archive: 'gray-500',
    new: 'brand-primary',
    forwarding_subject: 'gray-100',
    bulk_good: 'gray-500',
    bware: 'brand-danger',
    promotion: 'brand-danger',
    remaining_stock: 'brand-danger',
    order_item: 'gray-500',
};
const color = (str: string) => colorMap[str] || str;

const { t } = useI18n();
const textMap: Map = {
    archive: t('productBadges.archiveArticle'),
    new: t('productBadges.new'),
    forwarding_subject: t('productBadges.forwardingCompany'),
    bulk_good: t('productBadges.bulkyGood'),
    bware: t('productBadges.bwareArticle'),
    promotion: t('productBadges.specialOffer'),
    remaining_stock: t('productBadges.remainingStock'),
    order_item: t('productBadges.orderArticle'),
};
const text = (str: string) => textMap[str] || str;
</script>

<template>
    <SharedLabel
        v-for="(badge, index) in badges"
        :key="index"
        class="uppercase whitespace-nowrap"
        :class="{
            'mr-2': !noMargin && isHorizontal && index != Object.keys(badges).length - 1,
            'mb-2': !noMargin,
        }"
        :outline="badge === 'new'"
        :color="color(badge)"
        :size
    >
        {{ text(badge) }}
    </SharedLabel>
</template>
